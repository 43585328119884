div.heading2 {
    font-weight: 300;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: "Segoe UI Semibold","Segoe UI Semibold Web (West European)",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
}

h2.headingh2 {
    display: inline;
    font-size: inherit;
}

.physicalCategory {
    outline: 1px solid white;
    outline-offset: 5px;
    margin-left: 5px;
    width: calc(100% - 10px);
}