.categoryBlock {
    height: fit-content;
}

.category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    margin-right: 24px;
    margin-bottom: 28px;
    gap: 20%;
}

.categoryDetails {
    color: black;
}

.categoryHeader {
    font-family: "Segoe UI Semibold", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 18px;
    margin: 0px;
}

.categoryDescription {
    font-size: 14px;
    font-weight: 400;
}

.divider {
    margin-top: 28px;
    margin-bottom: 28px;
    height: 1px;
    width: 100%;
    align-self: center;
    background: rgb(229, 229, 229);
}


@media screen and (max-width: 479px) {
   
}

@media screen and (min-width: 480px) and (max-width: 639px) {}

@media screen and (min-width: 640px) and (max-width: 1023px) {}

@media screen and (min-width: 1024px) and (max-width: 1365px) {}

@media screen and (min-width: 1366px) and (max-width: 1919px) {}

@media screen and (min-width: 1920px) {}