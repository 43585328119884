.homev2 {
    margin-top: 80px;
    text-align: center;
}



.homev2__image {
    max-width: 100%;
    width: 43%;
}

.homev2__header {
    font-family: "Segoe UI Semibold", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 42px;
    margin: 64px 0 16px 0;
}

.homev2__text {
    font-size: 14px;
    font-weight: 400;
    width: 90%;
    margin: 0 auto;
    margin-top: 8px;
}

@media screen and (max-width: 479px) {
    .homev2 {
        margin-top: 32px;
    }

    .homev2__image {
        width: 69%;
    }

    .homev2__header {
        font-size: 20px;
        margin: 45px 0 4px 0;
    }

    .homev2__text {
        font-size: 12px;
        font-weight: 400;
        width: 100%;
    }
}

@media screen and (min-width: 480px) and (max-width: 639px) {}

@media screen and (min-width: 640px) and (max-width: 1023px) {}

@media screen and (min-width: 1024px) and (max-width: 1365px) {}

@media screen and (min-width: 1366px) and (max-width: 1919px) {}

@media screen and (min-width: 1920px) {}