.footer {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 30px;
    padding-top: 30px;
}

.toggle {
    font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    margin-top: 21px;
    margin-bottom: 9px;
}

.starting {
    font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    margin-top: 28px;
    margin-bottom: 7px;
}

.ending {
    font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    margin-bottom: 7px;
}

.image {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    margin-top: 10px;
}

.fileerros {
    flex-wrap: wrap;
}


.size {
    display: flex;
    color: #646464;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    justify-content: space-between;
    width: 272px;
    flex-wrap: wrap;
}

.date {
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;
    align-items: baseline;
    justify-content: space-between;
}

.text {
    color: #646464;
    margin-top: 0px;
}

.previewBlank {
    width: 100%;
    background-color: #F2F2F2;
    vertical-align: middle;
    line-height: 300px;
    text-align: center;
    color: #646464;
}

.preview {
    width: 100%;
}

.previewGray {
    width: 100%;
    filter: grayscale(70%);
}
