.page-container {
    width: -webkit-fill-available;
    display: block;
    height: 100%;
    max-height: calc(100vh - 48px);
    overflow-y: auto;
}

.grid-container{
    padding: 0 4%;
    margin: 0 auto;
}

.section-header{
    text-align: center;
    padding-bottom: 8px;
}

.background-images{
    height: -webkit-fill-available;
    min-height: 35vw;
    background-image: url('../../Assets/right-image.svg'), url('../../Assets/left-image.svg');
    background-repeat: no-repeat, no-repeat;
    background-position: top right, 0% calc(100% - 18%);
    background-size: 35%, 35%;
}

@media screen and (max-width: 479px) {
    .background-images{
        height: -webkit-fill-available;
        background-image: none;
    }
}

/*@media screen and (max-width: 800px) and (min-width: 500px) {
    .background-images{
        height: -webkit-fill-available;
        min-height: 400px;
        background-image: url('../../Assets/right-image.svg'), url('../../Assets/left-image.svg');
        background-repeat: no-repeat, no-repeat;
        background-position: top right, bottom left;
        background-size: 300px, 300px;
    }
}*/

.submission-success-background {
    background-position: bottom right, bottom left;
}

@media screen and (max-width: 720px) {
    .submission-success-background {
        background-image: none;
    }
}

.errorContainer {
    display: flex;
    justify-content: center;
    margin-top: 70px;
    flex-direction: column;
}

.errorImage {
    width: 160px;
    align-self: center;
}

.errorHeading {
    font-weight: 600; 
    text-align: center;
}

.errorMessage {
    width: 360px;
    text-align: center;
    margin-top: 0px;
    align-self: center;
}