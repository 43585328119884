.categoryBlock {
    height: fit-content;
}

.categoryBlockHighlighted {
    height: fit-content;
    background-color: beige;
}

.category {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    margin-right: 24px;
    margin-bottom: 28px;
    gap: 20%;
}

.categoryDetails {
    color: black;
}

.categoryDetailsHighlighted {
    color: #d90000 ;
}

.categoryHeader {
    font-family: "Segoe UI Semibold", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 18px;
    margin: 0px;
}

.categoryDescription {
    font-size: 14px;
    font-weight: 400;
}

.divider {
    height: 1px;
    width: 100%;
    align-self: center;
    background: rgb(229, 229, 229);
}

.custom-choice-group-option-focused, .custom-choice-group-option:hover{
    border-radius: 4px;
    box-shadow: 0 0 1px 0 inset;
    background-color: rgba(94, 94, 94, 0.15);
}

.custom-choice-group-option-focused {
    border: 2px solid grey !important;
}

.custom-choice-group-option{
    padding-top: 28px;
    border: 1px solid transparent;
}

@media (forced-colors: active){
    .custom-choice-group-option:hover, .custom-choice-group-option-focused{
        background-color: Highlight
    }
    .custom-choice-group-option-focused{
        border-color: Highlight;
    }
}