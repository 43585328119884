.accordion {
    margin: 0;
    padding: 0;
  }
  
  .accordion h3 {
    margin: 0;
    padding: 0;
  
  }
  
  .accordion:focus-within {
    border-color: #0064BF;
  }
  
  .accordion:focus-within h3 {
    background-color: hsl(0deg 0% 97%);
  }
  
  .accordion>*+* {
    border-top: 1px solid hsl(0deg 0% 52%);
  }
  
  .accordionTrigger {
    background: none;
    color: #0064BF;
    font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    position: relative;
    text-align: left;
    width: 100%;
    outline: none;
    border-width: 0px !important;
  }
  
  .accordionTrigger:focus,
  .accordionTrigger:hover {
    background: hsl(216deg 94% 94%);
  }
  
  .accordionTrigger:focus {
    outline: 4px solid transparent;
  }
  
  .accordion>*:first-child .accordionTrigger,
  .accordion>*:first-child {
    border-radius: 5px 5px 0 0;
  }
  
  .accordion>*:last-child .accordionTrigger,
  .accordion>*:last-child {
    border-radius: 0 0 5px 5px;
  }
  
  
  .accordion button::-moz-focus-inner {
    border: 0;
  }
  
  .accordionTitle {
    display: block;
    pointer-events: none;
    outline: none;
  }
  
  .accordionTrigger:focus .accordionTitle {
    border-color: hsl(216deg 94% 43%);
  }
  
  .accordionIcon {
    border: solid currentcolor;
    border-width: 0 2px 2px 0;
    height: 0.5rem;
    pointer-events: none;
    position: absolute;
    right: 2em;
    top: 50%;
    transform: translateY(-60%) rotate(-45deg);
    width: 0.5rem;
  }
  
  .accordionTrigger:focus .accordionIcon,
  .accordionTrigger:hover .accordionIcon {
    border-color: hsl(216deg 94% 43%);
  }
  
  .accordionTrigger[aria-expanded="true"] .accordionIcon {
    transform: translateY(-50%) rotate(45deg);
  }
  
  .accordionPanel {
    margin: 0;
  
  }
  
  /* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/4806035/ */
  .accordionPanel[hidden] {
    display: none;
  }
  
  .accordionBody {
    margin-left: 0.5em;
  }