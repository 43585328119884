.stickey-Header-Container{
    position: sticky;
    top: 0;
    z-index: 2;
}

.shell-container {
    width: -webkit-fill-available;
    display: block;
    height: 100%;
}