.hyperlink {
    color: #0064BF;
    text-decoration: none;
}

.create {
    align-self: start;
    margin-left: auto;
}

.heading {
    font-size: 16px;
    color: #171717;
    padding-bottom: 3px;
    font-weight:normal;
}

.incident {
    padding-bottom: 26px;
    display: flex;
    justify-content: space-between;
}

.heading2 {
    font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 18px;
    margin-top: 0px;
}

h3.heading3 {
    font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    margin-top: 14px;
}

.footer {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 30px;
    padding-top: 30px;
}

.desc {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 20px;
}

.text {
    color: #646464;
    width: 90%;
}

@media screen and (max-width: 479px) {
    .text {
        color: #646464;
        width: 80%;
    }
    .heading {
        font-size: 16px;
        color: #171717;
        padding-bottom: 3px;
        width: 76%;
    }
}