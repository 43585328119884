.fileInput {
    opacity: 0.0;
    width: 0;
    height: 0;
}

.dragContainer {
    border: 1px dashed black;
    background-color: rgba(255, 255, 255, 1);
    height: 64px;
    width: 100%;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
}

.dragContainer:focus-within {
    border: 2px solid rgb(0,120,212);
    border-radius: 2px;
    background-color: rgb(243, 242, 241);
}

.dragContainer:focus-within  .dragContainerText {
    border: 2px solid black !important;
}

.errorContainer {
    border: 1px dashed black;
    background-color: #FED9CC;
    height: 64px;
    width: 100%;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
}

.dragContainerText {
    position: relative;
    top: 35%;
    text-align: center;
    font-size: 14px;
    color: black;
}

.fileInput:focus + .dragContainer {
    outline-color: red;
    outline-width: 2px;
    outline-style: solid;
    outline-offset: 5px;
}
