.loader {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background-color: rgb(0,0,0,0.25);
    z-index: 1000001;
}
