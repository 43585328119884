.error-container-welcome {
    margin-bottom: 120px !important;
}

.exception-page {
    margin-bottom: 200px;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 48px);
    overflow-y: auto;
}

@media screen and (max-width: 479px){
    .error-container-welcome {
        margin-bottom: 60px !important;
    }

    .exception-page {
        margin-bottom: 80px;
    }
}