:root {
    --message-bar-icon-color: #D73B02;
    --message-bar-color: #FFFFFF;
    --message-bar-close-button-color: #171717;
    --base-distance-px: 4px;
    --base-distance-x2: (var(--base-distance-px) * 2);
}

body {
    margin: 0;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    /* height: 100vh; */
    overflow-y: hidden;
}
body.classic{
    color: #FFFFFF !important;
    background-color: var(--background-color) !important;
}
body.v2{

}

.classic [class*="body"] {
    color: inherit;
    background-color: inherit;
    height: 100%;
}

.classic #root {
    height: 100%;
}

    .classic #root h2.heading {
            font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
            font-weight: 300;
            font-size: 22px;
            margin-top: 20px;
            margin-bottom: 20px;
            color: #FFFFFF !important;
        }

        .classic #root h1.heading {
            font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
            margin-bottom: 0 !important;
            font-size: 42px;
            color: #FFFFFF !important;
        }

        .classic #root .ms-Label {
        color: #FFFFFF !important;
        font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    }

    .classic #root .ms-ChoiceFieldLabel {
        color: #FFFFFF !important;
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
        font-weight: normal;
    }

    .classic .submission-buttons {
    margin-top: 40px !important;
    margin-bottom: 200px;
}

.classic .margin-bottom-32 {
    margin-bottom: 32px;
}

.classic .ms-Checkbox.is-checked.is-enabled:hover .ms-Checkbox-checkbox {
    background-color: white;
}

.classic .ms-Checkbox.is-checked.is-enabled .ms-Checkbox-checkmark{
    color: rgb(96,94,92);
    opacity: 1;
}
.classic .ms-Checkbox.is-checked.is-enabled:hover .ms-Checkbox-checkmark{
    color: rgb(96,94,92);
    opacity: 1;
}

.classic .ms-Checkbox.is-enabled:hover .ms-Checkbox-checkmark{
    opacity: 0;
}
.classic .ms-Checkbox.is-enabled:focus-within {
    width: -moz-fit-content;
    width: fit-content;
    outline: 1px solid white !important;
}

.classic .ms-Checkbox-label {
    align-items: flex-start !important;
}

.classic h1 {
    margin-block-start: 0 !important;
}

.classic .success-page {
    margin-top: 172px;
    text-align: center;
}

.popup-form-control-container {
    display: inline-block;
    vertical-align: top;
}

@media screen and (max-width: 479px) {
    .classic .success-page {
        margin-top: 80px;
    }
}

@media screen and (max-width: 479px) {
    #root.classic h2.heading {
        font-size: 18px;
    }

    .classic .incident-category-header {
        margin-bottom: 12px;
    }
}

.classic .iconButton:focus-visible {
    outline: 1px solid white;
    inset: 0px;
    top: 3px;
    left: 4px;
}

.classic .iconButton {
    color: white;
    background: transparent;
    top: 3px;
    left: 4px;
}

.classic .iconButton:hover {
        color: white;
        background-color: rgba(242, 242, 242, .2);
    }

    .classic .iconButton:focus::after {
        outline: none !important;
    }

    .classic .ms-ChoiceField-wrapper:focus-within {
    outline: 1px solid white !important;
    inset: 0px !important;
}

.classic #l2PanelBackButton:focus-visible::after {
    content: "";
    position: absolute;
    inset: 2px;
    border: 1px solid transparent;
    outline: rgb(100, 100, 100) solid 1px;
    z-index: 1;
}

.classic .sectionHeading {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Segoe UI Semibold","Segoe UI Semibold Web (West European)",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
}

.visually-hidden { 
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute !important;
    height: 1px; 
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
    clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
    clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
    white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}

.site-margins{
    margin-inline-start: max(5%, 8px) !important;
    margin-inline-end: max(5%, 8px) !important;
}

.required-field-label::after{
    content: " *";
    color: rgb(164, 38, 44);
    padding-right: 12px;
}

.label-h3{
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    box-shadow: none;
    margin: 0px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
}


/* This style is used to change the bounding box of the Report it now logo/header in the header bar for better visible focus for high contrast users.*/
[class*="titleContainer"] > a {
    height: unset !important;
    line-height: unset;
    border: unset;
}