.welcome {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
}

.welcome-container{
    display: flex;
    justify-content: space-between;
}

.desc {
    font-size: 18px;
    margin-top: 16px;
    color: #FFFFFF !important;
}

.new-expereience-container{
    margin-top: 12px;
}

@media screen and (max-width: 479px) {
    .welcome-container{
        flex-direction: column-reverse;
    }

    .new-expereience-container{
        align-self: end;
    }

    .welcome {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    
    .desc {
        font-size: 14px;
        margin-top: 16px;
    }
}

#mainRegionHeader {
    line-height: 1;
    margin-left: -2px;
}