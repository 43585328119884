h3.heading3 {
    font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    margin-top: 14px;
}

.link {
    color: #0064BF !important;
    text-decoration: none;
}

p.text {
    color: #646464;
}

.linkOutVector {
    padding: 11px;
}
