.divider {
    margin-top: 28px;
    margin-bottom: 28px;
    height: 1px;
    width: 100%;
    align-self: center;
    background: rgb(229, 229, 229);
}

.footer {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 30px;
    padding-top: 30px;
}

h2.heading2 {
    font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 18px;
}

div.heading3 {
    font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.link {
    color: #0064BF !important;
    text-decoration: none;
}

.linkOutVector {
    padding: 11px;
}

.moveToBottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
}
 
@media screen and (max-height:479px){
    .moveToBottom{
       display: contents;
    }
}