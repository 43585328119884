div.heading {
    font-family: "Segoe UI Semibold", "Segoe UI Semibold Web (West European)", "Segoe UI Semibold", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-weight: 300;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

h2.headingh2 {
    display: inline;
    font-size: inherit;
}