.display-inline{
    display: inline;
    font-size: inherit;
}

.sovereign-heading{
    font-weight: 300;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: "Segoe UI Semibold","Segoe UI Semibold Web (West European)",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
}

.physical-incident-type-container .sovereign-heading{
    margin-top: 44px;
}

.digital-incident-information-container #sovereign-incident-dropdown{
    margin-bottom: 32px;
}