.c-stepper {
    display: flex;
    padding-inline-start: 0px !important;
    margin: 40px 0 41px 0;
}

.c-stepper__item__started, .c-stepper__item__notStarted, .c-stepper__item__completed {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
}

.c-stepper__item__started:before {
    --size: 3rem;
    content: "";
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #0064BF;
    margin: 0 auto 1rem;
}

.c-stepper__item__notStarted:before {
    --size: 3rem;
    content: "";
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: white;
    margin: 0 auto 1rem;
    border: 2px solid #949494;
}

.c-stepper__item__completed:before {
    --size: 3rem;
    content: "\002713";
    font-size: 28px;
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #0064BF;
    margin: 0 auto 1rem;
    color: white;
}

.c-stepper__item__started:not(:last-child):after, .c-stepper__item__notStarted:not(:last-child):after, .c-stepper__item__completed:not(:last-child):after {
    content: "";
    position: relative;
    top: calc(36px / 2);
    width: calc(100% - 36px - calc(clamp(0.25rem, 2vw, 0.5rem) * 2));
    left: calc(50% + calc(36px / 2 + clamp(0.25rem, 2vw, 0.5rem)));
    height: 2px;
    background-color: #e0e0e0;
    order: -1;
}

.c-stepper__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 0em;
    color: black;
    font-family: "Segoe UI Semibold","Segoe UI Semibold Web (West European)",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
}

.c-stepper__desc {
    color: grey;
    font-size: clamp(0.85rem, 2vw, 1rem);
    padding-left: clamp(0.25rem, 2vw, 0.5rem);
    padding-right: clamp(0.25rem, 2vw, 0.5rem);
}

.c-stepper__divider {
    height: 1px;
    width: 100%;
    align-self: center;
    background: rgb(229, 229, 229);
}

@media screen and (max-width: 479px) {
    .c-stepper{
        margin: 22px 0 14px 0;
    }
    .c-stepper__item__started:before {
        width: 24px;
        height: 24px;
        margin: 0 auto 0.5rem;
    }
    .c-stepper__item__notStarted:before {
        width: 24px;
        height: 24px;
        margin: 0 auto 0.5rem;
    }
    .c-stepper__item__completed:before {
        width: 24px;
        height: 24px;
        margin: 0 auto 0.5rem;
        font-size: 16px;
    }
    .c-stepper__title {
        font-size: 12px;
    }
    .c-stepper__item__started:not(:last-child):after {
        top: calc(24px / 2);
        width: calc(100% - 24px - calc(clamp(0.25rem, 2vw, 0.5rem) * 2));
        left: calc(50% + calc(24px / 2 + clamp(0.25rem, 2vw, 0.5rem)));
    }
    .c-stepper__item__notStarted:not(:last-child):after {
        top: calc(24px / 2);
        width: calc(100% - 24px - calc(clamp(0.25rem, 2vw, 0.5rem) * 2));
        left: calc(50% + calc(24px / 2 + clamp(0.25rem, 2vw, 0.5rem)));
    }
    .c-stepper__item__completed:not(:last-child):after {
        top: calc(24px / 2);
        width: calc(100% - 24px - calc(clamp(0.25rem, 2vw, 0.5rem) * 2));
        left: calc(50% + calc(24px / 2 + clamp(0.25rem, 2vw, 0.5rem)));
    }
}

@media screen and (forced-colors: active){
.c-stepper__item__started:before {
        background-color: Highlight;
    }
    .c-stepper__item__notStarted:before {
        background-color: Canvas;
        border: 2px solid Highlight;
    }
    .c-stepper__item__completed:before {
        background-color: Highlight;
        color: CanvasText;
    }
    .c-stepper__item__started:not(:last-child):after, .c-stepper__item__notStarted:not(:last-child):after, .c-stepper__item__completed:not(:last-child):after {
        background-color: Canvas;
    }
    .c-stepper__title {
        color: CanvasText;
    }
    .c-stepper__desc {
        color: CanvasText;
    }
    .c-stepper__divider {
        background-color: CanvasText;
    }
}